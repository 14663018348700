import smoothscroll from 'smoothscroll-polyfill';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import 'lazysizes'; // lazy load logos in footer
import 'javascripts/lib/compat/bootstrap';

import Cookies from 'js-cookie';
import {Component} from "javascripts/lib/component";
import {Event} from "javascripts/lib/store/event";
import {timeZone} from "javascripts/lib/time_zone"; // TODO consider making this a function
import {GoogleTagManager} from "javascripts/lib/integrations/google_tag_manager";

// Import our custom behaviors
import "../application";
// register Swiper custom elements
register();

/* global bootstrap */
window.bootstrap = require('bootstrap');

let initializersRun = false;

export class StoreLayout extends Component {

  constructor(...args) {
    super(...args);

    this._runLayoutInitializers();
  }

  _runLayoutInitializers() {
    if (initializersRun) {
      return;
    }
    initializersRun = true;

    // Sets the users timezone, was in time_zone.js initializer
    Cookies.set('titan.browser.time_zone_name', timeZone);
  }

  didLoad() {
    /**
     * We load the scripts that have the possibility of running for >50ms on init first so the TTI
     * (time to interactive) is not higher than it needs to be.
     *
     * More on this here:
     *  - https://docs.google.com/document/d/1GGiI9-7KeY3TPqS3YT271upUVimo-XiL5mwWorDUD4c/edit#
     *  - https://www.searchenginejournal.com/a-technical-seo-guide-to-lighthouse-performance-metrics/292703/
     */

    GoogleTagManager.load(
      this.config.googleTagManagerToken,
      this.config.googleTagManagerAuth,
      this.config.googleTagManagerEnv,
      this.config.gtmServerUrl,
      this.config.customerId,
      this.config.googleTagManageremailAddressSHA256,
      this.config.googleTagManageremailAddressSHA1,
      this.config.klaviyoExternalId
    );

    this._initNotices();
    this._initBackToTopBtn();
    this._initTooltips();

    Event.pageView(this.data);
  }

  _initNotices() {
    [...document.querySelectorAll('.toast-init')].forEach((el) => new bootstrap.Toast(el).show());
  }

  _initBackToTopBtn() {
    smoothscroll.polyfill();
    let $btn = this.hook('btn-back-to-top');
    $btn.on('click', function(e) {
      e.preventDefault();
      window.scrollTo({top: 0, behavior: 'smooth'});
      return false;
    });
  }

  _initTooltips() {
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
  }
}
