import store from 'store';
import {Logger} from "javascripts/lib/logger";
import {GoogleTagManager} from "javascripts/lib/integrations/google_tag_manager";


/* eslint-disable max-lines */
export class Event {

  static logger = Logger;

  static googleTagManagerClient = GoogleTagManager;

  static pageView(data) {
    this.googleTagManagerClient.pageView(data);
  }

  static productDetailViewed(data) {
    this.googleTagManagerClient.productDetailViewed(data.googleTagManager.productDetailViewed);
  }

  /**
   * triggered when user completes the checkout flow and reaches the order_completed page
   * @param  {JSON} data jbuilder data
   * @param {int} lastOrderCompleted ID of last completed order
   * @return {boolean}   return true if event is fired. false, otherwise.
   */
  static orderCompleted(data, lastOrderCompleted) {
    let orderId = data.checkout.order_id;

    if (lastOrderCompleted === orderId) return false;

    // set the orderId so that next time we don't try sending these again
    store.set('lastOrderCompleted', orderId);

    this.googleTagManagerClient.orderCompleted(data.googleTagManager.orderCompleted);

    return true;
  }
}
